/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  scroll-behavior: smooth;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family:
    'Open Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #666;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9em;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #777;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  img {
    margin-bottom: 5px;
  }
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    margin-top: 12px;
    margin-bottom: 12px;
    border: 0;
    width: 80%;
    max-width: 680px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      max-width: 90px;
      height: auto;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.3em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family:
        'Open Sans',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        'Helvetica Neue',
        Arial,
        'Noto Sans',
        sans-serif,
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
        'Noto Color Emoji';
    }
    span.small {
      display: none;
      margin-top: 3px;
      font-size: 16px;
      font-family: 'Open Sans', Verdana;
      font-weight: normal;
      color: $h2d-theme-primary;
      font-weight: 600;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: #252525;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten(#252525, 7.5%);
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}
.white-bg {
  background: #fff;
}
.dropdown-list .drop {
  &.drop-top {
    bottom: 39px;
    top: auto;
    border-top: 1px solid #a1c4e5 !important;
    border-bottom: 0;
  }
}
span.underline {
  text-decoration: underline;
}
.modal-content {
  border: 3px solid $h2d-theme-primary;
}
select#school {
  border-color: #ccc;
  max-width: 400px;
}
.text-red {
  color: #ca0000;
}
/* MODAL */
.member-modal {
  h1,
  h2,
  h3,
  h4 {
    color: #405ca5 !important;
  }
  p {
    font-size: 0.95em;
  }
  .btn-member {
    border-color: #405ca5;
    background: #405ca5;
    width: 200px;
    font-size: 0.9em;
    font-weight: 600;
    &:hover {
      background: #3b518a;
    }
  }
  input.form-control {
    border-color: #405ca5;
    max-width: 300px;
  }
  .errormsg {
    text-align: center;
    color: #de0000;
    margin: 2px auto;
    display: block;
    width: 100%;
  }
  .half.member {
    border-bottom: 1px solid #ebebeb;
    position: relative;
    &:after {
      width: 40px;
      margin-left: -20px;
      top: auto;
      left: 50%;
      bottom: -15px;
      display: block;
      margin-top: -15px;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      content: '-OR-';
      background: #fff;
      position: absolute;
      right: -15px;
      padding: 0 5px;
    }
    &.memberonly {
      border-right: 0;
      border-bottom: 0;
      &:after {
        display: none;
      }
    }
  }
}
#feesTabs {
  margin-bottom: -9999px !important;
  padding-bottom: 9999px;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      max-width: 110px;
      height: auto;
      margin-right: 10px;
    }
    span.big {
      display: inline-block;
      font-size: 1.3em;
    }
    span.small {
      display: inline-block;
    }
  }
  .mh-md-215 {
    min-height: 215px;
  }
}

// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    width: 70%;
    span.big {
      font-size: 2em;
    }
  }
  #header-wrap {
    border-top: 3px solid #252525;
    #header #support {
      display: block;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 65%;
    img {
      max-width: 155px;
      height: auto;
    }
  }
  .member-modal {
    .half.member {
      border-right: 1px solid #ebebeb;
      border-bottom: 0px;
      &:after {
        width: 30px;
        top: 50%;
        left: 102%;
        padding: 0;
      }
    }
  }
}

// Extra large devices (large desktops, 1080px and up)
@media (min-width: 1080px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #header-wrap {
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
}
