/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #e74a3b !default;
$orange: #fd7e14 !default;
$yellow: #f6c23e !default;
$green: #1cc88a !default;
$teal: #36b9cc !default;
$cyan: #17a2b8 !default;
$gray-600: #6c757d !default;
$paleblue: #e5e8ec !default;
$lightblue: #d1e8ff !default;

/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;

/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
$theme-primary: #0c2d63 !default;
$theme-secondary: #243f86 !default;
$theme-nav-primary: $theme-primary !default;
$theme-nav-secondary: $theme-secondary !default;
$link-color: #0085b7 !default;

/* H2D */
$h2d-theme-primary: #0092c8 !default;
$h2d-theme-secondary: #ffc600 !default;
$h2d-theme-nav-primary: #252525 !default;
$h2d-theme-nav-secondary: #0092c8 !default;
$h2d-link-color: #0092c8 !default;

/* RW */

/* DIP */

/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
$cta-red: #e62339 !default;
$cta-orange: #ff6633 !default;
$cta-green: #65af36 !default;
$cta-blue: #103d85 !default;
$cta-yellow: #ffc107 !default;
$cta-dark: #555555 !default;
$cta-light: #cccccc !default;

/* ----------------------------------------------------------------------------
    Text
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: $theme-primary !important;
}
.text-theme-secondary {
  color: $theme-secondary !important;
}
/* ----------------------------------------------------------------------------
    Backgrounds
----------------------------------------------------------------------------- */
.bg-theme-primary {
  background-color: $theme-primary;
}
.bg-theme-primary-dark {
  background-color: #333942;
}
.bg-theme-secondary {
  background-color: $theme-secondary;
}

/* ----------------------------------------------------------------------------
    Borders
----------------------------------------------------------------------------- */
